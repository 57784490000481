import React from "react";

import "./footer.css";

const footer = () => {
  return (
    <div className="main-footer">
      <p>© Copyright 2021 - Algirdas Rėkus.</p>
    </div>
  );
};

export default footer;
