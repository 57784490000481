import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import Header from "./shared/Header";
import Footer from "./shared/Footer";

const Home = React.lazy(() => import("./homeComponents/Home"));
const About = React.lazy(() => import("./aboutComponents/About"));
const Contacts = React.lazy(() => import("./contactsComponents/Contacts"));

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <Router>
          <Header />
          <Switch>
            <Route path="/About">
              <About />
            </Route>

            <Route path="/Contacts">
              <Contacts />
            </Route>

            <Route path="/">
              <Home />
            </Route>

            <Redirect to="/" />
          </Switch>
        </Router>
        <Footer />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
