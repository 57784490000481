import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import Lit from "./lt.json";
import Eng from "./en.json";

export const Context = React.createContext();

const Wrapper = (props) => {
  const [language, setLanguage] = useState(Lit);
  const [locale, setLocale] = useState("En");

  const selectEn = () => {
    setLocale("En");
    setLanguage(Eng);
  };
  const selectLt = () => {
    setLocale("Lt");
    setLanguage(Lit);
  };

  return (
    <Context.Provider value={{ locale, selectEn, selectLt }}>
      <IntlProvider messages={language} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
