import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";
import { Context } from "./Wrapper";

import logo from "../logo_s.png";

import "./Header.css";

const Header = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(Context);

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
      setOpen(false);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <React.Fragment>
      <nav className="header">
        <div className="header__items">
          <Link to="/">
            <img className="logop" src={logo} alt="#" />
          </Link>

          <ul>
            <Link to="/">
              <li>
                <FormattedMessage id="header.home" defaultMessage="Home" />
              </li>
            </Link>
            <Link to="/About">
              <li>
                <FormattedMessage id="header.about" defaultMessage="ABOUT ME" />
              </li>
            </Link>
            <Link to="/Contacts">
              <li>
                <FormattedMessage
                  id="header.contacts"
                  defaultMessage="CONTACTS"
                />
              </li>
            </Link>
          </ul>
        </div>
        <div className="lan">
          <button onClick={context.selectEn}>En</button>

          <p>|</p>
          <button onClick={context.selectLt}>Lt</button>
        </div>
      </nav>
      <div ref={myRef} onClick={handleClickInside}>
        <nav className="mob__header">
          <Link to="/">
            <img className="mob__logo" src={logo} alt="#" />
          </Link>
          <Link to="#">
            <div
              className="mob__menu"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <p>
                <FormattedMessage id="header.menu" defaultMessage="MENU" />
              </p>
              <ExpandMoreIcon
                className={open === true ? "rotate" : "rotateOff"}
                style={{ fill: "white", fontSize: "35px" }}
              />
            </div>
          </Link>
        </nav>
        <div
          className={open === false ? "mob__slideMenuOff" : "mob__slideMenu"}
        >
          <div className="slidemenu__items">
            <Link
              to="/"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <p className="slideMenu__item">
                <FormattedMessage id="header.home" defaultMessage="Home" />
              </p>
            </Link>
            <Link
              to="/About"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <p className="slideMenu__item">
                <FormattedMessage id="header.about" defaultMessage="ABOUT ME" />
              </p>
            </Link>
            <Link
              to="/Contacts"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <p className="slideMenu__item">
                <FormattedMessage
                  id="header.contacts"
                  defaultMessage="CONTACTS"
                />
              </p>
            </Link>

            <div className="slideMenu__item">
              <div className="slideMenu__lan">
                <button onClick={context.selectEn}>En</button>
                <p>|</p>
                <button onClick={context.selectLt}>Lt</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
